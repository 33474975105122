

































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import EventStudyDisplay from '@/partials/components/Events/StudyDisplay.vue'

@Component({
  components: {
    EventStudyDisplay
  }
})
export default class EventStudyReviewShow extends Vue {
  review = {
    eliminated: false,
    grade: 0,
    grades: []
  }

  created () {
    const { eventId, studyId, reviewId } = this.$route.params
    axios.get(`/event/${eventId}/study/${studyId}/reviews/${reviewId}`)
      .then(response => {
        this.review = camelCaseKeys(response.data.data, { deep: true })
      })
  }
}
